import { DeleteMessageEntity, Repository, NotificationTypes, ChatDocument, Roles } from "@/types";
import { mapTake } from "@/helpers/utility";
import { FirebaseApp } from "firebase/app";
import { User } from "firebase/auth";
import {
  getFirestore,
  query,
  collection,
  where,
  doc,
  getDocs,
  deleteDoc,
  onSnapshot,
  CollectionReference,
  QuerySnapshot,
  limit,
  orderBy,
  DocumentReference,
  writeBatch,
  updateDoc,
} from "firebase/firestore";
import _chunk from "lodash/chunk";
import FirestoreReferenceGenerator from "./FirestoreReferenceGenerator.class";
import RoleAccess from "@/plugins/RoleAccess";
import { useNotificationStore } from "@/stores/notifications";
import { useChatStore } from "@/stores/chat";
import { useStatisticsStore } from "@/stores/statistics";
import { QueueCategoryFilterEnum } from "@/types";
export class ChatRepository implements Repository {
  private _chatsCollectionRef: CollectionReference<ChatDocument>;
  private _claimedUnsubscribeFunction: () => void;
  private _unclaimedUnsubscribeFunction: () => void;
  private _queueNSFWUnsubscribeFunction: () => void;
  private _queueSFWUnsubscribeFunction: () => void;
  private _queueAgentOnlyUnsubscribeFunction: () => void;
  private initialized = false;
  private _chatProperties = [
    "customer",
    "profile",
    "isConversation",
    "isDeleted",
    "createdAt",
    "updatedAt",
    "lastMessage",
    "lastSenderType",
    "claimedBy",
    "claimExpiresAt",
    "domain",
    "origin",
    "trigger",
    "usedAttachments",
    "attachments",
    "lastMessageMessagePool",
  ];

  constructor(private _user: User, private _project: FirebaseApp, private _locale: string) {
    this._chatsCollectionRef = new FirestoreReferenceGenerator(this._project, this._locale).getChatsCollectionRef();
  }

  private createUnclaimedChatQuery(collection: CollectionReference) {
    return query(
      collection,
      orderBy("updatedAt", "desc"),
      where("claimedBy", "==", null),
      where("lastSenderType", "in", ["customer", "queueableTrigger"]),
      where("lastMessage.isFree", "==", false),
      limit(100),
    );
  }

  private createClaimedChatQuery(collection: CollectionReference) {
    return query(collection, orderBy("updatedAt", "desc"), where("claimedBy", "==", this._user.uid));
  }

  private createQueueCountQuery(collection: CollectionReference, category: QueueCategoryFilterEnum) {
    const commonConditions = [
      orderBy("updatedAt", "desc"),
      where("claimedBy", "==", null),
      where("lastSenderType", "in", ["customer", "queueableTrigger"]),
      where("lastMessage.isFree", "==", false),
    ];

    const additionalCondition = category === QueueCategoryFilterEnum.NSFW
        ? []
        : [where("lastMessageMessagePool", "==", category)];

    return query(collection, ...commonConditions, ...additionalCondition);
  }

  public async dispatch(
    action:
      | "enableListeners"
      | "disableAllListeners"
      | "enableClaimedChatListener"
      | "disableClaimedChatListener"
      | "enableUnclaimedChatListener"
      | "waitForClaim"
      | "deleteMessage"
      | "updateMessage",
    params: any,
  ): Promise<void> {
    if (this[action]) {
      await this[action](params);
    }
  }

  /**
   * Update firebase message doc with the abuse_modification_data value.
   * @param message {chatID, messageId, abuseModificationData}
   */
  public async updateMessage(message: {
    chatId: string;
    messageId: string;
    abuseModificationData: string;
  }): Promise<void> {
    const chatRef = doc(this._chatsCollectionRef, message.chatId);
    const chatsCollection = collection(chatRef, "messages");
    const messageRef = doc(chatsCollection, message.messageId);
    const abuseModificationData = JSON.parse(message.abuseModificationData);
    abuseModificationData["modified_by"] = this._user.uid;
    await updateDoc(messageRef, { abuse_modification_data: JSON.stringify(abuseModificationData) });

    const chatReference = await this.getChatReferenceFromLastMessageId(message.messageId);
    if (chatReference) {
      await updateDoc(chatReference, { "lastMessage.abuse_modification_data": JSON.stringify(abuseModificationData) });
    }
  }

  public async deleteMessage(message: { chatId: string; messageId: string }): Promise<void> {
    const docRef = doc(this._chatsCollectionRef, message.chatId);
    const chatsCollection = collection(docRef, "messages");
    const chatsCollectionRef = doc(chatsCollection, message.chatId);
    await deleteDoc(chatsCollectionRef);
  }

  /**
   * Deletes messages in chunks of 500.
   *
   * 500 is the max batch writes you can have in one batched
   * transaction.
   *
   * @param messages
   */
  public async deleteMessages(messages: DeleteMessageEntity[]): Promise<void> {
    const batches = _chunk(messages, 250);

    for (const batchOfMessages of batches) {
      const firestore = getFirestore(this._project);
      const batch = writeBatch(firestore);

      for (const message of batchOfMessages) {
        const docRef = doc(this._chatsCollectionRef, message.chatId);
        const documentCollection = collection(docRef, "messages");
        //get message document reference from the document collection
        const documentReference = doc(documentCollection, message.messageId);
        batch.delete(documentReference);

        const chatReference = await this.getChatReferenceFromLastMessageId(message.messageId);
        if (!chatReference) {
          continue;
        }

        batch.update(chatReference, { "lastMessage.content": "" });
      }

      batch.commit();
    }
  }

  private async getChatReferenceFromLastMessageId(lastMessageId: string): Promise<DocumentReference | null> {
    const chatsCollection = query(this._chatsCollectionRef, where("lastMessage.id", "==", lastMessageId), limit(1));
    const snapshot = await getDocs(chatsCollection);
    if (snapshot.empty) {
      return;
    }

    return snapshot.docs[0].ref;
  }

  public waitForClaim({ chatId }: { chatId: string }) {
    const docRef = doc(this._chatsCollectionRef, chatId);
    const unsubscribe = onSnapshot(docRef, (doc: any) => {
      const claimedBy = doc.data().claimedBy;
      if (!claimedBy) {
        return;
      }

      useChatStore().waitingForClaim = false;

      if (claimedBy !== this._user.uid) {
        useNotificationStore().addNotification({
          message: "The chat was claimed by another operator",
          type: NotificationTypes.Warning,
        });
      }
      unsubscribe();
    });
  }

  public enableListeners() {
    this.enableClaimedChatListener();

    if (this.initialized) {
      return;
    }

    this.initialized = true;

    if (RoleAccess.hasRole(Roles.Admin)) {
      this.enableUnclaimedChatListener();
    }
  }

  public enableClaimedChatListener() {
    this._claimedUnsubscribeFunction?.();

    this._claimedUnsubscribeFunction = onSnapshot(
      this.createClaimedChatQuery(this._chatsCollectionRef),
      this.constructChangeHandler(
        useChatStore().addClaimedChat,
        useChatStore().updateClaimedChat,
        useChatStore().removeClaimedChat,
      ),
    );
  }

  public disableClaimedChatListener() {
    this._claimedUnsubscribeFunction?.();
  }

  public async enableUnclaimedChatListener() {
    useChatStore().isLoadingUnclaimedChats = true;
    this._unclaimedUnsubscribeFunction?.();
    useChatStore().unclaimedChats = [];

    this._unclaimedUnsubscribeFunction = onSnapshot(
      this.createUnclaimedChatQuery(this._chatsCollectionRef),
      this.constructChangeHandler(
        useChatStore().addUnclaimedChat,
        useChatStore().updateUnclaimedChat,
        useChatStore().removeUnclaimedChat,
      ),
    );
    setTimeout(() => {
      useChatStore().isLoadingUnclaimedChats = false;
    }, 1000);
  }

  public async enableQueueCountListener() {
    await useStatisticsStore().setInitialQueues();
    
    this._queueNSFWUnsubscribeFunction?.();
    this._queueNSFWUnsubscribeFunction = onSnapshot(
      this.createQueueCountQuery(this._chatsCollectionRef, QueueCategoryFilterEnum.NSFW),
      this.constructChangeHandler(
        useStatisticsStore().addQueueCountNSFW,
        useStatisticsStore().updateQueueCount,
        useStatisticsStore().removeQueueCountNSFW,
      ),
    );

    this._queueSFWUnsubscribeFunction?.();
    this._queueSFWUnsubscribeFunction = onSnapshot(
      this.createQueueCountQuery(this._chatsCollectionRef, QueueCategoryFilterEnum.SFW),
      this.constructChangeHandler(
        useStatisticsStore().addQueueCountSFW,
        useStatisticsStore().updateQueueCount,
        useStatisticsStore().removeQueueCountSFW,
      ),
    );

    this._queueAgentOnlyUnsubscribeFunction?.();
    this._queueAgentOnlyUnsubscribeFunction = onSnapshot(
      this.createQueueCountQuery(this._chatsCollectionRef, QueueCategoryFilterEnum.AGENT_ONLY),
      this.constructChangeHandler(
        useStatisticsStore().addQueueCountAgentOnly,
        useStatisticsStore().updateQueueCount,
        useStatisticsStore().removeQueueCountAgentOnly,
      ),
    );

    // create one queue object with combined data
    useStatisticsStore().updateQueues();
  }

  public disableAllListeners() {
    this._unclaimedUnsubscribeFunction?.();
    this._queueNSFWUnsubscribeFunction?.();
    this._queueSFWUnsubscribeFunction?.();
    this._queueAgentOnlyUnsubscribeFunction?.();
    this.initialized = false;
  }

  private constructChangeHandler(
    addFunction: (doc: ChatDocument) => void,
    modifyFunction: (doc: ChatDocument) => void,
    removeFunction: (doc: ChatDocument) => void,
  ) {
    return (snapshot: QuerySnapshot) => {
      snapshot.docChanges().forEach((docChange: any) => {
        const takeChatProperties = mapTake(...this._chatProperties);
        const document = {
          id: docChange.doc.id,
          locale: this._locale,
          ...takeChatProperties(docChange.doc.data()),
        };

        switch (docChange.type) {
          case "added":
            addFunction(document);
            break;
          case "modified":
            modifyFunction(document);
            break;
          case "removed":
            removeFunction(document);
            break;
          default:
            break;
        }
      });
    };
  }
}
