import { defineStore } from "pinia";
import { DebugState, NotificationTypes, Operator, OperatorsCategories, OperatorsPoolCategory, QueueCategoryEnum, Category, OperatorsCategoriesDetailed } from "@/types";
import claimService from "@/services/claimService";
import { useAuthenticatorStore } from "@/stores/authenticator";
import { useNotificationStore } from "@/stores/notifications";

export const useDebugStore = defineStore("debug", {
  state: (): DebugState => ({
    loading: false,
    operators: [],
    connectedOperators: {
      operators: {
        SFW: { operators: [] },
        NSFW: { operators: [] },
        AGENT_ONLY: { operators: [] },
      },
    } as OperatorsCategories,
    operatorPools: [],
    operatorsPoolCategories: [
      {
        id: "SFW",
        label: "Snowflake",
        description: "This queue is designated for handling SFW (Safe for Work) messages specifically from users categorized as snowflake and require particular sensitivity.",
      },
      {
        id: "NSFW",
        label: "Generic",
        description: "This is the default queue where standard NSFW (Not Safe for Work) messages are received and managed.",
      },
      {
        id: "AGENT_ONLY",
        label: "Agent Only",
        description: "This is the dedicated queue for NSFW messages that require a response from a human agent, ensuring personalized interaction for messages needing direct engagement.",
      },
    ],
  }),

  getters: {
    getConnectedOperators: (state: DebugState): OperatorsCategories => {
      const result = {
        SFW: { operators: [] as Operator[] },
        NSFW: { operators: [] as Operator[] },
        AGENT_ONLY: { operators: [] as Operator[] },
      };

      Object.keys(state.connectedOperators.operators).forEach((category: string) => {
        const uniqueOperators = [] as Operator[];
        const seenIds = new Set();
        
        state.connectedOperators.operators[category as keyof OperatorsCategoriesDetailed].operators.forEach((operator) => {
          if (!seenIds.has(operator.id)) {
            seenIds.add(operator.id);
            uniqueOperators.push(operator);
          }
        });

        result[category as keyof OperatorsCategoriesDetailed] = { operators: uniqueOperators };
      });

      return { operators: result };
    },
  },

  actions: {
    async fetchOperators(): Promise<void> {
      this.operators = [];
      this.loading = true;
      
      const response = await claimService.operators.getAll();
      const formattedOperators = response.operators.map((operator: Operator) => ({
        ...operator,
        geos: operator.geos ? JSON.parse(operator.geos) : null,
        name: operator.id,
      }));
      
      this.operators = formattedOperators;
      this.loading = false;
    },

    async fetchConnectedOperators(): Promise<void> {
      this.connectedOperators = {
        operators: {
          SFW: { operators: [] },
          NSFW: { operators: [] },
          AGENT_ONLY: { operators: [] },
        },
      } as OperatorsCategories;
      
      this.loading = true;

      try {
        const response: OperatorsCategories = await claimService.operators.connected();
        const categories: QueueCategoryEnum[] = this.operatorsPoolCategories.map((category: Record<string, any>) => category.id);
        categories.forEach((category: string) => {
          const operatorsPerCategory: Category | undefined = response.operators[category as keyof OperatorsCategoriesDetailed];
          if (!operatorsPerCategory) {
            return;
          }
          
          const formattedOperators = operatorsPerCategory.operators.map((operator: Operator) => ({
            ...operator,
            geos: JSON.parse(operator.geos),
          }));
          this.connectedOperators.operators[category as keyof OperatorsCategoriesDetailed].operators.push(...formattedOperators);
        });        
      } catch (error: any) {
        useNotificationStore().addNotification({
          message: error.message || 'An error occurred while fetching operators.',
          type: NotificationTypes.Error,
        });
      } finally {
        this.loading = false;
      }
    },

    async fetchOperatorPools(geo: string): Promise<void> {
      this.operatorPools = [];
      this.loading = true;

      try {
        const response = await claimService.redis.pools(geo, useAuthenticatorStore().tenant);
        this.operatorPools = response;
      } catch (error: any) {
        useNotificationStore().addNotification({
          message: error.message || 'An error occurred while fetching operator pools.',
          type: NotificationTypes.Error,
        });
      } finally {
        this.loading = false;
      }
    },

    getCategoryInfo(category: string): { label: string; description: string } {
      // Initialize categoryinfo with empty values
      const categoryinfo = {
        label: category,
        description: 'No description available',
      };

      this.operatorsPoolCategories.forEach((item: OperatorsPoolCategory) => {
        const categoryNameAsIdValue = category.toLowerCase().replace(' ', '_');
        if (item.id.toLowerCase() === categoryNameAsIdValue) {
          categoryinfo.label = item.label;
          categoryinfo.description = item.description;
        }
      });

      return categoryinfo;
    },
  },
});