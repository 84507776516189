import { defineStore } from "pinia";
import { MessageDocument, ChatDocument } from "@/types/index";
import { QueueCount, StatisticsState, QueueCountPools } from "@/types";
import { useAuthenticatorStore } from "@/stores/authenticator";

export const useStatisticsStore = defineStore("statistics", {
  state: (): StatisticsState => ({
    messages: [],
    queueCountsNSFW: [],
    queueCountsSFW: [],
    queueCountsAgentOnly: [],
    queueCounts: [], 
    activeChat: null,
    chatMessages: [],
    nextChatDocPage: null,
    previousChatDocPage: null,
    nextPokeDocPage: null,
    previousPokeDocPage: null,
    pokes: [],
  }),

  getters: {
    reversedChatMessages: (state: StatisticsState) => {
      return [...state.chatMessages].reverse();
    },
  },

  actions: {
    async setInitialQueues() {
      const initialQueues = useAuthenticatorStore().user.geos.map((geo: string) => {
        const [languageCode, countryCode] = geo.split("_");
        return {
          locale: geo,
          count: 0,
          countryCode,
          languageCode,
        } as QueueCount;
      });
      this.queueCountsNSFW = structuredClone(initialQueues);
      this.queueCountsSFW = structuredClone(initialQueues);
      this.queueCountsAgentOnly = structuredClone(initialQueues);
    },
    pushChatMessage(message: MessageDocument) {
      if (this.chatMessages.find((chatMessage) => chatMessage.id === message.id)) {
        return;
      }
      this.chatMessages.push(message);
    },
    removeQueueCountNSFW(unclaimedChat: ChatDocument) {
      const index = this.queueCountsNSFW.findIndex((item: QueueCount) => item.locale === unclaimedChat.locale);
      if (index !== -1) {
        this.queueCountsNSFW[index].count -= 1;
      }
    },
    removeQueueCountSFW(unclaimedChat: ChatDocument) {
      const index = this.queueCountsSFW.findIndex((item: QueueCount) => item.locale === unclaimedChat.locale);
      if (index !== -1) {
        this.queueCountsSFW[index].count -= 1;
      }
    },
    removeQueueCountAgentOnly(unclaimedChat: ChatDocument) {
      const index = this.queueCountsAgentOnly.findIndex((item: QueueCount) => item.locale === unclaimedChat.locale);
      if (index !== -1) {
        this.queueCountsAgentOnly[index].count -= 1;
      }
    },
    addQueueCountNSFW(unclaimedChat: ChatDocument) {
      const [languageCode, countryCode] = unclaimedChat.locale.split("_");

      const index = this.queueCountsNSFW.findIndex((item: QueueCount) => item.locale === unclaimedChat.locale);
      if (index !== -1) {
        this.queueCountsNSFW[index].count += 1;
        return;
      }
      this.queueCountsNSFW.push({
        locale: unclaimedChat.locale,
        languageCode,
        countryCode,
        count: 1,
      });
    },
    addQueueCountSFW(unclaimedChat: ChatDocument) {
      const [languageCode, countryCode] = unclaimedChat.locale.split("_");

      const index = this.queueCountsSFW.findIndex((item: QueueCount) => item.locale === unclaimedChat.locale);
      if (index !== -1) {
        this.queueCountsSFW[index].count += 1;
        return;
      }
      this.queueCountsSFW.push({
        locale: unclaimedChat.locale,
        languageCode,
        countryCode,
        count: 1,
      });
    },
    addQueueCountAgentOnly(unclaimedChat: ChatDocument) {
      const [languageCode, countryCode] = unclaimedChat.locale.split("_");

      const index = this.queueCountsAgentOnly.findIndex((item: QueueCount) => item.locale === unclaimedChat.locale);
      if (index !== -1) {
        this.queueCountsAgentOnly[index].count += 1;
        return;
      }
      this.queueCountsAgentOnly.push({
        locale: unclaimedChat.locale,
        languageCode,
        countryCode,
        count: 1,
      });
    },
    combineQueues() {
      this.queueCounts = this.queueCountsSFW.map((itemSFW: QueueCount): QueueCountPools => {
        const { locale, countryCode, languageCode } = itemSFW;
      
        return {
          locale,
          countSFW: itemSFW.count,
          countNSFW: this.findCountByLocale(this.queueCountsNSFW, locale) - this.findCountByLocale(this.queueCountsAgentOnly, locale) - itemSFW.count,
          countAgentOnly: this.findCountByLocale(this.queueCountsAgentOnly, locale),
          countryCode,
          languageCode,
        };
      });
    },
    updateQueues() {
      this.combineQueues();
      // refresh every 10 seconds (from snapshot)
      setInterval(() => {
          this.combineQueues();
      }, 10000);
    },
    findCountByLocale(list: QueueCount[], locale: string): number {
      const item = list.find((entry: QueueCount) => entry.locale === locale);
      return item.count ?? 0;
    },
    // updateQueueCount function created for constructChangeHandler inside ChatRepository.
    // it is shared together with chat module and requires three arguments. empty updateQueueCount
    // created to provide three arguments every  time constructChangeHandler is used.
    updateQueueCount() {
      return;
    },
  },
});
